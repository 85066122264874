import { Registry } from 'InterfaceBundle'

/**
 * Style
 */
import './styles/table.scss'
import './styles/dialog.scss'
import './styles/fields.scss'

/**
 * Components
 */
import BlFormField from './components/BlFormField.vue'
import BlForm from './components/BlForm.vue'
import BlFormSubmit from './components/BlFormSubmit.vue'
import BlFormFieldText from './components/BlFormFieldText.vue'
import BlFormFieldPassword from './components/BlFormFieldPassword.vue'
import BlFormFieldDate from './components/BlFormFieldDate.vue'
import BlFormFieldDatetime from './components/BlFormFieldDatetime.vue'
import BlFormFieldNumber from './components/BlFormFieldNumber.vue'
import BlFormFieldCollection from './components/BlFormFieldCollection.vue'
import BlFormFieldCollectionItem from './components/BlFormFieldCollectionItem.vue'
import BlCollectionAdd from './components/BlCollectionAdd.vue'
import BlCollectionRemove from './components/BlCollectionRemove.vue'
import BlCollectionMove from './components/BlCollectionMove.vue'
import BlFormFieldBoolean from './components/BlFormFieldBoolean.vue'
import BlFormFieldOneToOne from './components/BlFormFieldOneToOne.vue'
import BlFormFieldChoice from './components/BlFormFieldChoice.vue'
import BlFormFieldTextarea from './components/BlFormFieldTextarea.vue'
import BlFormFieldModel from './components/BlFormFieldModel.vue'
import BlFormFieldRadio from './components/BlFormFieldRadio.vue'
import BlFormFieldRichtext from './components/BlFormFieldRichtext.vue'
import BlFormFieldMultimodel from './components/BlFormFieldMultimodel.vue'
import BlFormFieldColor from './components/BlFormFieldColor.vue'
import BlFormFieldEmail from './components/BlFormFieldEmail.vue'
import BlFormFieldFormoptionselector from './components/BlFormFieldFormoptionselector.vue'
import BlFormFieldHidden from './components/BlFormFieldHidden.vue'
import BlFormFieldScore from './components/BlFormFieldScore.vue'
import BlFormFieldSignature from './components/BlFormFieldSignature'
import BlFormFieldSignatureDialog from './components/BlFormFieldSignatureDialog'
import BlFormFieldTime from './components/BlFormFieldTime'
import BlFormFieldPhone from './components/BlFormFieldPhone'
import BlFormFieldVoice from './components/BlFormFieldVoice'
import BlStandaloneField from './components/BlStandaloneField'
import BlStandaloneSortable from './components/BlStandaloneSortable'
import BlStandaloneSortableItem from './components/BlStandaloneSortableItem'
import BlFormFieldPlace from './components/BlFormFieldPlace'
import BlFormFieldGeo from './components/BlFormFieldGeo'
import BlFormFieldMulticheckbox from './components/BlFormFieldMulticheckbox'

Registry.components(BlFormField, BlForm, BlFormSubmit, BlFormFieldText, BlFormFieldDate, BlFormFieldDatetime, BlFormFieldNumber, BlFormFieldCollection, BlFormFieldCollectionItem, BlCollectionAdd, BlCollectionRemove, BlFormFieldBoolean, BlFormFieldChoice, BlFormFieldTextarea, BlFormFieldModel, BlFormFieldRadio, BlFormFieldRichtext, BlFormFieldMultimodel, BlFormFieldColor, BlFormFieldEmail, BlFormFieldFormoptionselector, BlFormFieldPassword, BlCollectionMove, BlFormFieldHidden, BlFormFieldScore, BlFormFieldSignature, BlFormFieldSignatureDialog, BlStandaloneField, BlFormFieldTime, BlFormFieldPhone, BlFormFieldVoice, BlStandaloneSortable, BlStandaloneSortableItem, BlFormFieldOneToOne, BlFormFieldPlace, BlFormFieldGeo, BlFormFieldMulticheckbox)

/**
 * Directives
 */

/**
 * Services
 */
import { FormEvents as FormEventsService } from './services/FormEvents.js'

export const FormEvents = FormEventsService