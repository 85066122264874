<template>
	<div class="designerToolbar" v-if="!hidden">
		<div class="bl-loader-line" v-if="reloading"></div>
		<input type="checkbox" class="bl-checkbox" v-model="livereload" />
		<label @click="livereload = !livereload">Livereload</label>
		<hr />
		<div class="view" v-if="currentRoute" @click="editView()">
			<icon>view_quilt</icon>
			{{ currentRoute.component }}
		</div>
		<hr v-if="currentForms" />
		<div class="form" v-for="form in currentForms" :key="form" @click="editForm(form)">
			<icon>dynamic_form</icon>
			{{ form.name }}
		</div>
		<span style="flex: 1"></span>
		<button class="bl-icon-button" style="font-size: 20px" @click="selfClose()">close</button>
	</div>
</template>

<script>
import { ModelChangeEventHelpers, Api } from 'ModelBundle'
import { Router, ViewLoader } from 'InterfaceBundle'
import { FormEvents } from 'Bundles/FormBundle/services/FormEvents'

export default {
	name: 'InterfaceDesignerToolbar',
	props: ['selfClose'],
	data() {
		return {
			livereload: true,
			reloading: false,
			currentRoute: null,
			hidden: false,
			currentForms: []
		}
	},
	methods: {
		editView() {
			window.open(Router.generateUri('internals/view/form', {id: this.currentRoute.id}))
		},
		editForm(form) {
			window.open(Router.generateUri(form.__route[0], form.__route[1]))
		},
		parseForms() {
			if(FormEvents.activeForms.length) {
				let req = {
					data: {
						fields: [{name: 'name'}],
						filters: ['name', 'IN', FormEvents.activeForms],
						model: 'internals.form',
						limit: -1
					}
				}
				Api.post('api/', req).then(resp => this.currentForms = resp.data.data)
			}
			else {
				this.currentForms = []
				this.$forceUpdate()
			}
		},
		handleRouteChange() {
			this.currentRoute = Router.currentRoute?.definition
			this.hidden = false
			if(this.currentRoute?.component == 'InternalsIntegratedEditor') this.hidden = true
			this.$nextTick(() => document.querySelector('#app > div').style.height = this.hidden ? '100%' : 'calc(100% - 35px)')
		}
	},
	mounted() {
		this.livereloadSub = ModelChangeEventHelpers.listen('internals.view')
		this.livereloadSub.subscribe(() => {
			if(this.livereload && this.currentRoute && this.currentRoute.url.substr(0, 10) != 'internals/') {
				this.reloading = true
				ViewLoader.update(true).once(() => {
					Router.handleRoute()
					this.reloading = false
				})
			}
		})

		this.handleRouteChange()
		this.routeSub = Router.change.subscribe(() => this.handleRouteChange())

		this.parseForms()
		this.formSub = FormEvents.activeFormChange.subscribe(() => this.parseForms())
	},
	unmounted() {
		ModelChangeEventHelpers.unsubscribe(this.livereloadSub)
		this.routeSub.unsubscribe()
		this.formSub.unsubscribe()
		document.querySelector('#app > div').style.height = '100%'
	}
}
</script>

<style scoped lang="scss">
	.designerToolbar {
		display: flex;
		align-items: center;
		position: fixed;
		width: calc(100% - 10px);
		top: 100%;
		margin-top: -35px;
		height: 35px;
		left: 0;
		z-index: 99999;
		background-color: #292A2D;
		padding: 0 5px;
		color: white;

		.bl-loader-line {
			position: absolute;
			margin-top: -38px;
			margin-left: -5px;
		}

		.bl-checkbox {
			margin-top: -2px;
			margin-right: 10px;
			transform: scale(.8);
		}

		.bl-checkbox:after {
			background-color: #292A2D;
		}

		.bl-icon-button {
			padding: 5px;
			color: white;
		}

		label {
			cursor: pointer;
			margin-right: 10px;
			font-size: 12px;
		}

		hr {
			border-right: 1px solid #494C50;
			height: 100%;
			margin: 0 10px;
		}

		.view, .form {
			cursor: pointer;
			display: flex;
			font-size: 12px;
			align-items: center;
			border-radius: 5px;
			padding: 1px 6px;
			margin: 0 5px;
			border: 1px solid;

			icon {
				font-size: 18px;
				margin-right: 3px;
			}
		}

		.view {
			border-color: #d2c8ee;
			color: #d2c8ee;
		}

		.form {
			border-color: #dbeecc;
			color: #dbeecc;
		}
	}
</style>