<template>
	<div style="position: relative;">
		<div v-bl-input :class="{errored: field.isErrored() && field.getTouched()}" class="__suffix">
			<label>{{ field.label }}</label>
			<input ref="field" type="text" v-mask="mask" v-model="modelStr" @focus="handleFocus()" @change="fieldChange()" @blur="field.setTouched()" @keyup="autoComplete($event)" :tabindex="tabindex" />
			<span class="suffix material-icons" @click="toggleCalendar()" style="visibility: visible;">calendar_today</span>
		</div>
		<input type="date" v-if="mobile" ref="nativeInput" class="nativeInput" @change="setModelFromNative()" />
		<BlCalendarInput v-else @change="setCalendarValue($event)" :value="model" :format="getFormat()" :opened="showCalendar" @openedChange="showCalendar = $event" />
	</div>
</template>

<script>
import { mask } from 'vue-the-mask'
import { DateFormat, ViewServices, Variables } from 'InterfaceBundle'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
dayjs.extend(customParseFormat)
import utc from 'dayjs/plugin/utc'
dayjs.extend(utc)

export default {
	name: 'BlFormFieldDate',
	props: ['field', 'tabindex'],
	data() {
		return {
			model: this.field.value,
			modelStr: this.field.value,
			fieldChanged: false,
			mask: null,
			showCalendar: false,
			invalidDate: false,
			mobile: Variables.mobile
		}
	},
	methods: {
		toggleCalendar() {
			if(this.mobile) {
				this.$refs.nativeInput.showPicker()
				this.$refs.nativeInput.focus()
			}
			else this.showCalendar = !this.showCalendar
		},
		setCalendarValue(value) {
			this.modelStr = value
			this.fieldChanged = true
			this.field.setValue(this.field.options.native ? dayjs(this.modelStr, DateFormat.transformPHPFormatToJS(this.getFormat()), true).toDate() : this.modelStr)
			this.toggleCalendar()
		},
		/**
		 * Autocomplete year
		 */
		autoComplete(ev) {
			if(!ev.key.match(/[0-9-]/)) return
			if(!this.field.options.autocomplete) return
			let autocompleteMask = DateFormat.createMask(this.getFormat().replace('yyyy', '~'))
			autocompleteMask = this.modelStr + autocompleteMask.substr(this.modelStr.length)
			if(!autocompleteMask.includes('#') && autocompleteMask.includes('~')) {
				let now = new Date()
				let formattedStr = autocompleteMask.replace('~', now.getFullYear())
				let dateObject = dayjs(formattedStr, DateFormat.transformPHPFormatToJS(this.getFormat()), true).toDate()
				if(!isNaN(dateObject.getTime())) {
					let days = (dateObject - now) / (1000 * 60 * 60 * 24)
					if(days > 180) this.modelStr = autocompleteMask.replace('~', now.getFullYear() - 1)
					else if(days < -180) this.modelStr = autocompleteMask.replace('~', now.getFullYear() + 1)
					else this.modelStr = formattedStr
				}
			}
		},
		fieldChange() {
			let dateObject = dayjs(this.modelStr, DateFormat.transformPHPFormatToJS(this.getFormat()), true).toDate()
			this.fieldChanged = true
			this.invalidDate = false
			if(isNaN(dateObject.getTime())) {
				this.field.setValue(null)
				if(this.modelStr) this.invalidDate = true
			}
			else this.field.setValue(this.field.options.native ? dateObject : this.modelStr)
		},
		getFormat() {
			return this.field.options.format ? this.field.options.format : ViewServices.interfaceData.intl.date.dateformat
		},
		handleFocus() {
			if(this.mobile) {
				this.toggleCalendar()
				this.field.setTouched()
				document.activeElement.blur()
			}
		},
		setModelFromNative() {
			const date = this.$refs.nativeInput.valueAsDate
			this.modelStr = date ? dayjs(date).format(DateFormat.transformPHPFormatToJS(this.getFormat())) : ''
			this.fieldChange()
		},
		setNativeValue() {
			if(this.mobile) this.$refs.nativeInput.valueAsDate = this.modelStr ? dayjs.utc(this.modelStr, DateFormat.transformPHPFormatToJS(this.getFormat()), true).toDate() : null
		},
	},
	created() {
		this.mask = DateFormat.createMask(this.getFormat())
		this.field.emitter.focus.subscribe(() => this.$refs.field.focus())
		this.field.emitter.change.subscribe(() => {
			this.model = this.field.value
			if(!this.fieldChanged) {
				if(this.field.options.native) this.modelStr = this.field.value ? dayjs(this.field.value).format(DateFormat.transformPHPFormatToJS(this.getFormat())) : ''
				else this.modelStr = this.field.value
			}
			this.setNativeValue()
			this.fieldChanged = false
		})
		if(this.field.options.native) this.modelStr = this.field.value ? dayjs(this.field.value).format(DateFormat.transformPHPFormatToJS(this.getFormat())) : ''
	},
	mounted() {
		this.setNativeValue()
	},
	directives: {
		mask
	}
}
</script>

<style scoped lang="scss">
	div.bl-input .suffix.material-icons {
		font-size: 20px;
		margin-top: 12px;
		cursor: pointer;
	}

	.nativeInput {
		display: none;
	}
</style>
