<template>
	<div v-bl-dropdown="{input: 'input', list: 'ul', value: dropdownOptions}">
		<div class="searchContainer" :class="{hasResults: results.length > 0}">
			<icon>search</icon>
			<input :placeholder="$t('interface.shortcut.placeholder')" type="text" v-model="term" @input="search()" ref="input" @keydown.enter="handleEnter()" />
			<button class="bl-icon-button" @click="close()">close</button>
		</div>
		<ul>
			<li v-for="item in results" :key="item.id" @click="handle(item)">
				<div v-if="item.showGroup" class="groupItem">
					<div class="group appMainMenu level2">
						<h1 :style="{'--bundle-color': '#' + item.group.color}">
							<span>{{ item.group.label }}</span>
						</h1>
					</div>
				</div>
				<div>
					<icon v-if="item.icon">{{ item.icon }}</icon>
					<span>{{ item.name }}</span>
				</div>
			</li>
		</ul>
	</div>
</template>

<script>
import { ViewServices, Router, Dialog } from 'InterfaceBundle'

export default {
	name: 'BlInterfaceShortcut',
	data() {
		return {
			term: '',
			results: [],
			dropdownOptions: {}
		}
	},
	methods: {
		handle(item) {
			if(item) {
				this.close()
				setTimeout(() => Router.navigate(item.link, true), 10)
			}
		},
		handleEnter() {
			this.handle(this.results[this.dropdownOptions.activeIndex])
		},
		search() {
			const term = this.unaccent(this.term)
			const termLen = term.length
			if(this.term.length) {
				this.results = JSON.parse(JSON.stringify(this.shortcuts)).filter(r => r.search.filter(t => t.substr(0, termLen) == term).length)
				if(this.results.length > 8) this.results.splice(8)
				let lastGroup = -1
				for(let item of this.results) {
					if(JSON.stringify(item.group) != JSON.stringify(lastGroup)) {
						item.showGroup = true
						lastGroup = item.group
					}
				}
			}
			else this.results = []
		},
		close() {
			Dialog.close(false)
		},
		unaccent(string) {
			return (string + '').normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase()
		}
	},
	mounted() {
		this.$refs.input.focus()
		let menuItems = {}
		for(let item of ViewServices.interfaceData.mainMenu) {
			if(item.label) menuItems[item.label] = {
				label: this.$t(item.label),
				color: item.color
			}
		}
		let id = 1
		this.shortcuts = ViewServices.interfaceData.interfaceShortcuts.filter(shortcut => {
			return Router.canAccess(shortcut.link)
		}).map(shortcut => {
			let ret = JSON.parse(JSON.stringify(shortcut))
			ret.name = this.$t(ret.name)
			ret.search = this.unaccent(ret.name).split(' ')
			ret.id = id
			id++
			if(ret.group) {
				ret.group = menuItems[ret.group]
			}
			return ret
		}).sort((a, b) => (a.group?.label + '').localeCompare(b.group?.label))
	}
}
</script>

<style lang="scss">
	.bl-dialog-container.fullScreen.blInterfaceShortcutDialogContainer {
		background-color: transparent;
		padding: 0;
		width: 100%;
		max-width: 100%;
		height: 100%;
		max-height: 100%;

		> div {
			width: calc(100% - 100px);
			height: calc(100% - 100px);
			padding: 50px;
			position: absolute;

			.searchContainer {
				display: flex;
				position: relative;

				input {
					background-color: var(--bl-surface);
					border: 1px solid var(--bl-border);
					width: 100%;
					outline: none;
					font-size: 20px;
					padding: 11px 48px 9px 48px;
					border-radius: var(--bl-border-radius);
					font-family: 'Product sans';
					font-weight: 600;
				}

				icon {
					pointer-events: none;
					position: absolute;
					font-size: 30px;
					margin: 9px;
					color: var(--bl-legend);
				}

				button.bl-icon-button {
					position: absolute;
					font-size: 30px;
					right: 0;
					color: var(--bl-legend);
					padding: 6px;
					margin-right: 2px;
				}
			}

			.searchContainer.hasResults > input {
				border-bottom-left-radius: 0;
				border-bottom-right-radius: 0;
			}

			ul {
				background-color: var(--bl-surface);
				list-style-type: none;
				margin: 0;
				padding: 0;
				border-bottom-left-radius: var(--bl-border-radius);
				border-bottom-right-radius: var(--bl-border-radius);
				overflow: hidden;

				li {
					padding: 12px;
					user-select: none;
					margin: 0;
					cursor: pointer;
					transition: background-color .2s;

					div {
						font-size: 16px;
						display: flex;
						align-items: center;

						icon {
							font-size: 20px;
							margin-right: 10px;
							color: var(--bl-legend);
						}
					}

					.groupItem {
						background-color: var(--bl-surface);
						cursor: default;
						padding: 6px 12px;
						margin: -12px -10px 10px -11px;

						div > h1 {
							margin: 0 0 0 -5px;
							font-size: 14px;

							> span {
								font-weight: 600;
							}
						}
					}
				}

				li:hover:not(.groupItem), li.hovered:not(.groupItem) {
					background-color: var(--bl-background);
				}
			}
		}
	}
</style>