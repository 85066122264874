import { Api } from 'ModelBundle'
import { Router } from './Router.js'
import { Container } from 'Main'
import { ViewServices } from './ViewServices.js'
import { Variables } from './Variables.js'
import { Snackbar } from './Snackbar.js'
import { EventEmitter } from './EventEmitter.js'
import { Auth } from 'AuthBundle'
import { Dialog } from './Dialog.js'
import { ViewCache } from './ViewCache.js'
import { mask } from 'vue-the-mask'

/**
 * CSS management
 */
let cssMixinRegisteredComponents = {}
const cssMixin = {
	mounted() {
		const vueDefinition = this.$options
		const componentName = vueDefinition.name
		if(cssMixinRegisteredComponents[componentName]) cssMixinRegisteredComponents[componentName].count++
		else {
			let style = document.createElement('style')
			style.type = 'text/css'
			style.appendChild(document.createTextNode(vueDefinition.css))
			document.head.appendChild(style)
			cssMixinRegisteredComponents[componentName] = {count: 1, el: style}
		}
	},
	unmounted() {
		const componentName = this.$options.name
		if(cssMixinRegisteredComponents[componentName].count > 1) cssMixinRegisteredComponents[componentName].count--
		else {
			cssMixinRegisteredComponents[componentName].el.remove()
			delete cssMixinRegisteredComponents[componentName]
		}
	}
}

export const ViewLoader = {
	componentCollection: [],
	loaded: false,
	data: null,
	ready: new EventEmitter(),
	isReady: false,

	_parseViews(views) {
		Router.reset()
		let errors = []
		for(let view of views) {
			/* eslint-disable no-unused-vars */
			let viewServices = ViewServices
			/* eslint-enable no-unused-vars */
			if(view.bindings.data && view.bindings.data != '{}') {
				let dataBinding = view.bindings.data.slice(0, -1).trim()
				view.bindings.data = '() => { return ' + view.bindings.data.slice(0, -1) + (dataBinding.substr(dataBinding.length - 1) == ',' ? '' : ',') + '"services":viewServices}}'
			}
			else view.bindings.data = '() => { return {"services":viewServices}}'

			let valid = true
			for(let key in view.bindings) {
				try {
					eval('view[key] = ' + view.bindings[key])
				} catch(e) {
					let errorMessage = 'Syntax error on "' + view.name + '" in binding "' + key + '", component registration was canceled'
					errors.push(errorMessage)
					console.error(errorMessage + ' : ', e)
					valid = false
				}
			}
			view.directives = {mask}

			if(valid) {
				if(view.route) Router.register(view.route, view.name)
				delete view.route
				this.componentCollection.push(view.name)

				if(view.css) view.mixins = [cssMixin]
				Container.component(view.name, view)
			}
		}
		if(errors.length) Dialog.alert({
			title: 'Error on component registration',
			content: errors.join('<br />')
		})
	},

	/**
	 * Mount views
	 */
	mountViews() {
		return new Promise(resolve => {
			if(!this.loaded) {
				this.loaded = true
				ViewCache.get('interfaceData').once(cached => {
					if(localStorage.getItem('Interface.showDesignerToolbar')) {
						ViewCache.remove('interfaceData')
						cached = null
					}
					Api.get('interface/init/', cached ? {} : {refreshCache: true}).then(resp => {
						if(!cached) {
							let cacheData = {}
							for(let key of resp._cache) cacheData[key] = resp[key]
							ViewCache.set('interfaceData', cacheData).once(() => this.mountInterface(resp, resolve))
							cached = {}
						}
						else this.mountInterface({...cached, ...resp}, resolve)
					}).catch(() => {
						Auth.logout()
						Variables.initialize(true)
					})
				})
			}
			else resolve()
		})
	},

	/**
	 * Mount interface
	 */
	mountInterface(resp, resolve) {
		this.data = resp
		ViewServices.interfaceData = this.data
		ViewServices.interfaceUpdate = (silent = false, refreshCache = true) => this.update(silent, refreshCache)
		Auth.permissions = this.data.userPreferences.permissions
		Auth.superuser = this.data.userPreferences.superuser
		Variables.setInterfaceVariables(resp.variables, resp.i18n.direction, resp.userPreferences)
		this._parseViews(resp.views)
		this.isReady = true
		this.ready.emit()
		if(resolve) resolve()
		this.handleVersion(this.data.interfaceVersion)
	},

	/**
	 * Unmount views
	 */
	unmountViews() {
		Router.unload()
		ViewCache.remove('interfaceData')
		for(let item of this.componentCollection) delete Container._context.components[item]
		this.componentCollection = []
		this.loaded = false
		this.isReady = false
	},

	/**
	 * Update interface
	 */
	update(silent = false, refreshCache = true) {
		const ret = new EventEmitter()
		const snackbar = silent ? null : Snackbar.open({
			clickToClose: false,
			autoClose: false,
			component: 'InterfaceUpdateSnackbar'
		})
		Api.get('interface/init/', {refreshCache: refreshCache}).then(resp => {
			localStorage.setItem('Interface.version', resp.interfaceVersion)
			let cacheData = {}
			for(let key of resp._cache) cacheData[key] = resp[key]
			ViewCache.remove('interfaceData').once(() => {
				ViewCache.set('interfaceData', cacheData).once(() => {
					this.mountInterface(resp, null)
					if(snackbar) snackbar.hide()
					ret.emit()
					if(!silent) location.reload()
				})
			})
		})
		return ret
	},

	/**
	 * Handle interface version change on view mount
	 * @param {integer} version
	 */
	handleVersion(version) {
		//If dialog leave, wait for update
		if(Router.dialogLeave) {
			Router.change.subscribe(() => {
				setTimeout(() => this.handleVersion(), 500)
			})
		}
		else {
			const currentVersion = localStorage.getItem('Interface.version')
			if(!currentVersion) localStorage.setItem('Interface.version', version)
			else if(parseInt(currentVersion) != version) this.update()
		}
	}
}